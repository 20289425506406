import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(27ch, 1fr));
  grid-gap: 5em;
  justify-content: space-between;
  align-items: stretch;

  > a {
    line-height: inherit;
    border: 1px solid ${props => props.theme.colors.gray};
    transition: border 0.5s, transform 0.5s;

    > article {
      padding: 2em;
      display: grid;
      grid-template-rows: auto auto 1fr auto;
      grid-gap: 1em;

      > header h3 {
        color: ${p => p.theme.colors.primaryLight};
      }

      .thumbnail {
        width: 100%;
        max-height: 200px;
        object-fit: cover;
      }

      p {
        color: ${p => p.theme.colors.gray};
        text-align: justify;
        :last-child {
          margin: 0;
        }
      }

      .tech {
        border-left: 1px solid ${p => p.theme.colors.secondary};
        padding-left: 1em;
        text-align: left;
      }
    }

    :hover,
    :focus {
      border-color: ${p => p.theme.colors.primary};

      @media (min-width: 500px) {
        transform: translate(15px, 15px);
      }
    }
  }
`

const GithubRepo = ({ repo, className }) => (
  <article className={className}>
    <header>
      <h3>{repo.name}</h3>
      <span>&#9733; {repo.stargazers.totalCount}</span>
    </header>
    <h4>By: {repo.owner.login}</h4>
    <p>{repo.description}</p>
    <footer>
      <a href={repo.url} aria-label={repo.name + ' Github'}>
        View Github
      </a>
      {repo.homepageUrl && (
        <a href={repo.homepageUrl} aria-label={repo.name + ' Site'}>
          View Site
        </a>
      )}
    </footer>
  </article>
)

const RepoList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(27ch, 1fr));
  gap: 3em;
`

const StyledGithubRepo = styled(GithubRepo)`
  font-size: 0.9rem;
  padding: 1em;
  border: 1px solid ${props => props.theme.colors.gray};
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  transition: border 0.5s, transform 0.5s;

  > h4 {
    color: ${props => props.theme.colors.grayDarker};
  }

  > header,
  > footer {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    align-items: flex-start;
    justify-content: space-between;

    > a {
      line-height: inherit;
    }

    > * {
      margin: 0;
    }
  }

  :hover,
  :focus-within {
    border-color: ${p => p.theme.colors.primary};
    transform: perspective(300px) translate3d(15px, 15px, 5px);
  }
`

const ProjectsPage = ({
  data: {
    allMdx: { edges: featuredProjects },
    allGithubToprepositories: { edges: topRepos },
    allGithubRepositoriescontributedto: { edges: openSource },
  },
  location,
}) => (
  <>
    <SEO
      title="Projects"
      description="Levi Butcher's Projects"
      path={location.pathname}
    />
    <h1>Projects</h1>
    <section>
      <h2>Featured Projects</h2>
      <ProjectsList>
        {featuredProjects.map(({ node }) => (
          <Link
            to={node.fields.slug}
            aria-label={node.frontmatter.title}
            key={node.id}
          >
            <article>
              <Img
                fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                alt={node.frontmatter.title}
                className="thumbnail"
              />
              <header>
                <h3>{node.frontmatter.title}</h3>
              </header>
              <p>{node.frontmatter.description}</p>
              <p className="tech">{node.frontmatter.tech.join(', ')}</p>
            </article>
          </Link>
        ))}
      </ProjectsList>
    </section>
    <section>
      <h2>Open Source Contributions</h2>
      <RepoList>
        {openSource.map(({ node }) => (
          <StyledGithubRepo repo={node} key={node.name} />
        ))}
      </RepoList>
    </section>
    <section>
      <h2>Top Github Repositories</h2>
      <RepoList>
        {topRepos.map(({ node }) => (
          <StyledGithubRepo repo={node} key={node.name} />
        ))}
      </RepoList>
    </section>
  </>
)

export const query = graphql`
  {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fields: { slug: { regex: "/^/projects//" } } }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            tech
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allGithubToprepositories {
      edges {
        node {
          homepageUrl
          description
          name
          owner {
            login
          }
          stargazers {
            totalCount
          }
          url
        }
      }
    }
    allGithubRepositoriescontributedto(
      filter: { stargazers: { totalCount: { gt: 10 } } }
    ) {
      edges {
        node {
          name
          description
          homepageUrl
          stargazers {
            totalCount
          }
          url
          owner {
            login
          }
        }
      }
    }
  }
`

export default ProjectsPage
